<template>
  <div class="popUp">
    <div class="popUp__inner">
      <div class="popUp__title main__title">
        Оставьте заявку и наш<br> менеджер Вам перезвонит
      </div>
      <div class="popUp__group">
        <input
          type="text"
          class="popUp__input"
          name="Name"
          placeholder="Имя"
        >
      </div> 
      <div class="popUp__group">
        <input
          type="text"
          class="popUp__input"
          name="Name"
          placeholder="Телефон"
        >
      </div>
      <button class="btn btn--black">
        Связаться
      </button>
    </div>
    <button
      class="popUp__close"
      @click="closeModal()"
    >
      <img
        src="@/assets/images/close.svg"
        style="width: 100%; height: 100%; object-fit: cover;"
        alt="heavycar-image"
      >
    </button>
  </div>
</template>

<script>
export default {
    name: 'PopUp',
    filters: {
        addComma(val) {
            return val.toLocaleString()
        }
    },
    mounted() {
    const popUp = document.querySelector('.popUp');
    popUp.addEventListener('click', e=>{
       if (e.target.closest('.popUp__inner') === null ){
        popUp.classList.remove('popUp__active')
        document.querySelector('body').classList.remove('lock')
       } 
    })
    },
    methods: {
        openModal() {
            document.querySelector('.popUp').classList.add('popUp__active')
        },
        closeModal() {
            document.querySelector('.popUp').classList.remove('popUp__active')
            document.querySelector('body').classList.remove('lock')
        }
    }
}
</script>
